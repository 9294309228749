import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from './default-preset/preset'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
        light: {
          primary: '#00acdc', // Set your primary color here
        },
        dark: {
          primary: '#00acdc', // Optionally set the primary color for dark theme as well
        },
      },
  },
})
